.graph {
  margin-top: $grid-gutter-width;
  min-height: calc(100% - 155px);

  .p-inputtext {
    border-color: $primary;
    color: #0B1226;
    background: $white;
  }

  .input-group {
    margin: 0;
    width: auto;

    @include media-breakpoint-down(sm) {
      justify-content: center;
      width: 100%;
    }
  }

  .input-group-text {
    border: 1px solid $primary;
    background: $primary;

    i {
      color: #F2F2F2;
    }
  }

  .p-calendar .p-datepicker {
    background: $primary;

    .p-datepicker-header {
      background: $primary;
    }
  }

  .p-listbox {
    margin: 0 $grid-gutter-width;
    border-color: $primary;
    background: $white;

    @include media-breakpoint-down(md) {
      margin-top: 10px;
    }

    .p-listbox-item {
      color: #0B1226;

      &:not(.p-highlight):not(.p-disabled) {
        :hover :focus {
          color: $white;
          background: $primary;
        }
     }

      &.p-highlight {
        color: $white;
        background: $primary;
      }
    }
  }

  .p-field-checkbox {
    display: flex;
    align-items: center;
    justify-content: space-between;

    label {
      margin: 5px 0;
    }
  }

  .p-checkbox{
    margin: 0 7px;
  }

  .p-checkbox-box {
    background: #F2F2F2;
  }
}

.graph-information {
  margin-bottom: $grid-gutter-width;
  border: 1px solid $primary;
  background: #F2F2F2;

  .p-button-primary {
    margin: 0 10px;
  }
}

.graph-desc {
  width: 100%;
  padding: 10px;
  font-size: 18px;

  @include media-breakpoint-down(xs) {
    font-size: 16px;
  }
}

.graph-attr-heading {
  font-weight: $font-weight-bold;
  text-transform: uppercase;
}

.graph-range-dates {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: $grid-gutter-width;
  margin-bottom: $grid-gutter-width;
  padding: 10px $grid-gutter-width;

  @include media-breakpoint-down(md) {
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.graph-range-dates-separator {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }

  div {
    width: 15px;
    height: 2px;
    margin: 0 10px;
    background: #0B1226;
  }
}

.graph-group-heading {
  font-weight: $font-weight-bold;
  line-height: 2;
}

.graph-view-wrapper {
  padding-left: math.div($grid-gutter-width, 2);
  padding-right: math.div($grid-gutter-width, 2);

  @include media-breakpoint-down(lg) {
    overflow-x: scroll;
  }
}
