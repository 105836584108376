.popup-form {
  &.confirm {
    .p-dialog .p-dialog-footer .p-button {
      @extend .p-button-primary;
      @extend .dark;

      @include media-breakpoint-down(sm) {
        margin: 5px 10px;
      }
    }
  }

  h3 {
    margin-top: math.div($grid-gutter-width, 2);
    text-align: center;
    font-size: 24px;
  }

  hr {
    background: #F2F2F2;
  }

  .p-dialog {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 800px;
    }

    .p-dialog-header, .p-dialog-content, .p-dialog-footer {
      background: $primary;
    }

    .p-dialog-header {
      justify-content: center;
      border-color: #F2F2F2;
    }

    .p-dialog-title {
      color: #F2F2F2;
      font-size: 28px;

      @include media-breakpoint-down(xs) {
        font-size: 24px;
      }
    }

    .p-dialog-footer {
      border-color: #F2F2F2;
      text-align: center;

      @include media-breakpoint-down(xs) {
        .p-button {
          margin: 5px 0;
        }
      }
    }
  }

  .form-control, select {
    border-color: #F2F2F2;
    background-color: #F2F2F2;
  }

  .input-group-prepend span {
    padding-right: 18px;
    padding-left: 18px;
    border-color: $terciary;
    color: #F2F2F2;
    background: $terciary;

    @include media-breakpoint-down(xs) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.popup-sensors-wrapper {
  justify-content: center;
}

.popup-sensor {
  margin: 0 10px;
}

.popup-buttons-wrapper {
  display: flex;
  justify-content: space-between;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }

}

.popup-buttons-group {
  @include media-breakpoint-down(md) {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &:first-child {
      margin-bottom: 10px;
    }
  }
}

.popup-buttons {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  width: 100%;

  @include media-breakpoint-down(xs) {
    justify-content: space-between;
  }
}
