.input-group {
    margin-bottom: 1rem;
}

.input-group-prepend span {
    padding-right: 18px;
    padding-left: 18px;
    border-color: $terciary;
    color: #F2F2F2;
    background: $terciary;
    height: 100%;
}