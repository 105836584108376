.dashboard-unit-wrapper {
  width: 100%;
}

.dashboard-unit {
  align-items: center;
}

.dashboard-unit-heading {
  text-align: right;

  @include media-breakpoint-down(xs) {
    text-align: center;
  }
}


.dashboard {
  padding: $grid-gutter-width 0;

  h3 {
    margin: 0;
    font-weight: $font-weight-bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include media-breakpoint-down(sm) {
      max-width: 260px;
      font-size: 16px;
    }

    @include media-breakpoint-down(xs) {
      margin-bottom: 10px;
    }
  }

  h4 {
    margin: 0;

    @include media-breakpoint-down(xs) {
      margin-bottom: 10px;
    }
  }

  hr {
    margin: 0;
    border: 1px solid #619CE2;
  }

  .p-accordion {
    .p-accordion-header {
      .p-accordion-header-link {
        padding-top: math.div($grid-gutter-width, 2);
        padding-bottom: math.div($grid-gutter-width, 2);
        color: #0B1226;
        background: #F2F2F2;
        border: none;
      }

      &:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
        color: #0B1226;
        background: #BCBCBC;
      }

      &:not(.p-disabled).p-highlight {
        .p-accordion-header-link {
          color: #0B1226;
          background: #BCBCBC;
        }
      }

      .p-splitbutton-defaultbutton {
        margin-left: math.div($grid-gutter-width, 2);
      }
    }

    .p-accordion-content {
      padding: 0;
      color: #0B1226;
      background: #F2F2F2;
      border: none;

      .ng-star-inserted {
        &:nth-child(2n) .row {
          background: #D3D3D3;
        }

        .row {
          margin: 0;
          padding: 5px 0 5px 50px;

          :hover :focus {
            cursor: pointer;
            color: $white;
            background: #619CE2;
          }
        }
      }

      .p-button {
        margin: 0 5px;
        padding: 5px 7px;
      }
    }
  }

  .p-tabview {
    .p-tabview-title {
      font-size: 16pt;
    }

    .p-tabview-nav li .p-tabview-nav-link {
      border-radius: 0;
      border: none;
    }

    .p-tabview-nav-link {
      background: #FFFFFF !important;
      color: #0B1226 !important;
    }

    .p-highlight {
      .p-tabview-nav-link {
        color: #0B1226 !important;
        background: #BCBCBC !important;
      }
    }

    .p-tabview-panels {
      background: #FFFFFF !important;
      padding: 0;
    }
  }
}

.dashboard-sensor-heading-wrapper {
  display: flex;
  align-items: center;
  height: 100%
}

.dashboard-sensor-heading {
  text-align: right;

  @include media-breakpoint-down(xs) {
    text-align: center;
  }
}

.dashboard-button-separator {
  display: inline-flex;
  align-items: center;
  vertical-align: bottom;
  margin: 0 math.div($grid-gutter-width, 2);
  width: 2px;
  height: 100%;
  background: $secondary;
}

.p-dialog {
  background: $primary
}
