@use 'sass:math';

@import "/node_modules/bootstrap/scss/bootstrap";

$primary: #174B97;
$secondary: #727271;
$terciary: #1B3059;

@import "themes";
@import "fonts";
@import "login";
@import "navbar";
@import "dashboard";
@import "graph";
@import "popup-form";
@import "forms";

.p-tabview .p-tabview-panels {
  color: $terciary;
}