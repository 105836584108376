.login {
  height: calc(100% - 45px);
  color: $white;

  .container {
    height: 100%;
    align-content: center;
  }

  h1 {
    margin: 0;
    font-size: 0;
  }

  .card-header {
    padding: 0;
  }

  .card {
    width: 400px;
    height: 380px;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 10px;
    background: $primary;
    border: 2px solid $primary;
    
    .form-control {
      border-color: #F2F2F2;
      background-color: #F2F2F2;
    }
  }

  .input-group{
    margin-bottom: 1rem;
  }

  .input-group-prepend span {
    padding-right: 18px;
    padding-left: 18px;
    border-color: $terciary;
    color: #F2F2F2;
    background: $terciary;
    height: 100%;
  }
}

.login-form-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.login-logo {
  margin-bottom: $grid-gutter-width;
  border-radius: 10px 10px 0 0;
  text-align: center;
  background: #F2F2F2;

  img {
    width: 300px;

    @include media-breakpoint-down(xs) {
      width: 280px;
    }
  }
}

.login-heading {
  text-align: center;
  font-size: $h3-font-size;
}

.login-copyright {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-top: 50px;
  text-align: center;
  color: $white;
  background: $primary;

  p {
    margin-bottom: 0;
    padding: math.div($grid-gutter-width, 2) 0;
  }

  a {
    color: $white;
  }
}
