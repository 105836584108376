html,body{
  height: 100%;
  color: #0B1226;
  background: $white;
}

.p-button-primary {
  padding-right: $grid-gutter-width;
  padding-left: $grid-gutter-width;
  color: #F2F2F2;
  border-color: #1B3059;
  background: $primary;

  &:enabled:hover:focus {
      color: #F2F2F2;
      border-color: #2067BC;
      background: #2067BC;

    @include media-breakpoint-down(xs) {
      padding-right: math.div($grid-gutter-width, 2);
      padding-left: math.div($grid-gutter-width, 2);
    }
  }

  i {
    margin-right: 5px;
  }

  &.dark {
    border-color: #F2F2F2;
    background: $terciary;

    &:enabled :hover :focus {
        color: $terciary;
        border-color: #F2F2F2;
        background: #F2F2F2;
    }
  }
}

footer {
  padding: 10px 0;
  border-top: 1px solid $primary;
  text-align: center;
  background: #F2F2F2;

  p {
    margin: 0;
  }

  a {
    color: $primary;

    :hover :focus {
      color: $primary;
    }
  }
}
