.navbar-wrapper {
  height: 90px;
  border-bottom: 1px solid $primary;
  background: #F2F2F2;

  h1 {
    margin: 0;
    font-size: 0;
  }

  h2 {
    margin: 0 5px;
    color: #0B1226;
    text-transform: uppercase;
    font-size: 15px;

    @include media-breakpoint-down(lg) {
      margin: 0 3px;
      font-size: 15px;
    }

    @include media-breakpoint-down(md) {
      font-size: 18px;
    }
  }

  .container {
    padding: 0;
    height: 100%;

    @include media-breakpoint-down(md) {
      max-width: 100%;
    }
  }
}

.navbar {
  position: relative;
  height: 100%;
  background: #F2F2F2;

  button {
    outline: none;
    border: 0;
  }
}

.navbar-nav {
  width: 40%;

  @include media-breakpoint-down(md) {
    width: 100%;
    text-align: center;
  }

  &.right {
    justify-content: flex-end;
  }

  &.left {
    justify-content: flex-start;
  }
}

.nav-link {
  cursor: pointer;
}

.navbar-brand {
  margin: 0;

  img {
    width: 200px;

    @include media-breakpoint-down(xs) {
      width: 150px;
    }
  }

  &.mobile {
    @include media-breakpoint-up(lg) {
      display: none;
    }

    @include media-breakpoint-down(md) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }
  &.desktop {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

.navbar-toggler {
  position: absolute;
  top: 50%;
  right: math.div($grid-gutter-width, 2);
  width: 60px;
  height: 45px;
  z-index: 1;
  transform: translateY(-50%);

  span {
    display: block;
    position: absolute;
    height: 5px;
    width: 100%;
    background: $primary;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transition: .25s ease-in-out;
    z-index: 2;

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2) {
      top: 18px;
    }

    &:nth-child(3) {
      top: 36px;
    }
  }
}

.navbar-toggler.open {
  span {
    &:nth-child(1) {
      top: 18px;
      transform: rotate(135deg);
    }
    &:nth-child(2) {
      opacity: 0;
      left: -60px;
    }
    &:nth-child(3) {
      top: 18px;
      transform: rotate(-135deg);
    }
  }
}

.navbar-collapse {
  width: calc(100% + 20px);
  padding-bottom: 1px;
  justify-content: space-between;
  transition-duration: 0.2s;

  @include media-breakpoint-down(md) {
    position: absolute;
    left: -(math.div($grid-gutter-width, 2));
    top: 90px;
    width: calc(100% + 20px);
    background: $primary;
    z-index: 2;

    .nav-link h2 {
      margin: 12px 0;
      color: #F2F2F2;
    }
  }
}

.nav-link-user {
  display: flex;
  align-items: center;
  cursor: default;

  @include media-breakpoint-down(md) {
    justify-content: center;
  }

  h2 {
    color: $secondary;

    @include media-breakpoint-down(md) {
      margin: 12px 0;
      color: #F2F2F2;
    }
  }

  :hover :focus {
    color: $secondary;
  }
}
